import { Step } from 'pf-ui';

export const ExamStep = {
  PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
  WAITING_TRAINING: 'WAITING_TRAINING',
  TRAINING_QUESTIONS: 'TRAINING_QUESTIONS',
  WAITING_EXAM: 'WAITING_EXAM',
  SESSION_END: 'SESSION_END',
  EXPLANATION_TRAINING: 'EXPLANATION_TRAINING',
  TRAINING_CORRECTION: 'TRAINING_CORRECTION',
  EXAM: 'EXAM',
  RESULTS: 'RESULTS'
} as const;

export const SessionExamStep = {
  PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
  TRAINING_QUESTIONS: 'TRAINING_QUESTIONS',
  OFFICIAL_TEST: 'OFFICIAL_TEST',
  DISPLAY_RESULTS: 'DISPLAY_RESULTS',
  SESSION_END: 'SESSION_END',
} as const;

export type ExamStepType =
  'PERSONAL_INFORMATION'
  | 'WAITING_TRAINING'
  | 'EXPLANATION_TRAINING'
  | 'TRAINING_QUESTIONS'
  | 'TRAINING_CORRECTION'
  | 'WAITING_EXAM'
  | 'EXAM'
  | 'SESSION_END'
  | 'RESULTS'

export const DISPLAYED_EXAM_STEPS =
  ['PERSONAL_INFORMATION', 'WAITING_TRAINING', 'WAITING_EXAM', 'SESSION_END'];


export const examStepTranslation: Record<string, ExamStepViewModel> = {
  [ExamStep.PERSONAL_INFORMATION]: {
    pageTitle: 'Mes informations',
    stepTitle: 'Vérification des informations'
  },
  [ExamStep.WAITING_TRAINING]: {
    pageTitle: 'Déroulement de la session',
    stepTitle: 'Explication du déroulé de la session'
  },
  [ExamStep.TRAINING_QUESTIONS]: {
    pageTitle: 'Questions d\'entraînement',
    stepTitle: 'Questions d\'entraînement'
  },
  [ExamStep.WAITING_EXAM]: {
    pageTitle: 'Examen',
    stepTitle: 'Préparation de l’examen'
  },
  [ExamStep.SESSION_END]: {
    pageTitle: 'Résultats',
    stepTitle: 'Attente des résultats'
  }
};

export const sessionExamStepTranslation: Record<SessionExamSteps, ExamStepViewModel> = {
  [SessionExamStep.PERSONAL_INFORMATION]: {
    pageTitle: 'Mes informations',
    stepTitle: 'Vérification des informations'
  },
  [SessionExamStep.TRAINING_QUESTIONS]: {
    pageTitle: 'Déroulement de la session',
    stepTitle: 'Explication du déroulé de la session'
  },
  [SessionExamStep.OFFICIAL_TEST]: {
    pageTitle: 'Question d\'entraînement',
    stepTitle: 'Question d\'entraînement'
  },
  [SessionExamStep.DISPLAY_RESULTS]: {
    pageTitle: 'Affichage des résultats d\'examen',
    stepTitle: 'Affichage des résultats d\'examen'
  },
  [SessionExamStep.SESSION_END]: {
    pageTitle: 'Clôture de la session d\'examen',
    stepTitle: 'Clôture de la session d\'examen'
  }
};

export const examStepWaitingMessage: Record<string, string | undefined> = {
  [ExamStep.PERSONAL_INFORMATION]: undefined,
  [ExamStep.WAITING_TRAINING]:
  '<p>Les instructions de l’examen de la Direction des Transports Terrestres vont bientôt commencer. </p>' +
  '<p>Merci de patienter jusqu’à ce que le surveillant lance l’entraînement. </p>',
  [ExamStep.TRAINING_QUESTIONS]: undefined,
  [ExamStep.WAITING_EXAM]:
  '<p>Les questions d\'entraînement sont terminées.</p>' +
  '<p>L’examen {{exam_session_name}} va bientôt commencer. Merci de patienter jusqu’à ce que le surveillant lance l’examen. </p>',
  [ExamStep.SESSION_END]:
    'L\'épreuve théorique générale est terminée. Merci de patienter pour vos résultats.'
};

export type ExamSteps = (typeof ExamStep)[keyof typeof ExamStep];
export type SessionExamSteps = (typeof SessionExamStep)[keyof typeof SessionExamStep];

export type CandidateStep = Step<ExamSteps> & {
  waitingMessage?: string;
};

export type ExamStepViewModel = {
  pageTitle: string;
  stepTitle: string;
};
